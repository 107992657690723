import React, { lazy, useEffect, useState, useTransition } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ShareModal from "../ShareModal";
import TraderBanner from "./TraderBanner";
import TriviaQuizzes from "./TriviaQuizzes";
import SquaresBanner from "./SquaresBanner";

const Charts = lazy(() => import("./Charts"));
const Commentary = lazy(() => import("../Matches/Commentary"));
const Feed = lazy(() => import("../Matches/Feed"));
const Lineup = lazy(() => import("../Matches/Lineup"));
const ScoreBox = lazy(() => import("../Matches/ScoreBox"));
const Scorecard = lazy(() => import("../Matches/Scorecard"));
const Standings = lazy(() => import("../Matches/Standings"));
const SubMenu = lazy(() => import("../Matches/SubMenu"));
const Trading = lazy(() => import("../Matches/Trading"));

export const MatchDetail = () => {
  const [isPending, startTransition] = useTransition();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);

  const xlocation = useLocation();
  const queryParams = new URLSearchParams(xlocation.search);
  const match_id = queryParams.get("id");
  const complete = queryParams.get("status");
  const selectedParam = queryParams.get("selected");

  const [batting, setBatting] = useState(null);
  const [ballIds, setBallIds] = useState([]);
  const [bowling, setBowling] = useState();
  const [commentary, setCommentary] = useState([]);
  const [CurPos, setCurPos] = useState([0, 0, 0]);
  const [eventStatus, setEventStatus] = useState();
  const [feed, setFeed] = useState([]);
  const [format, setFormat] = useState(20);
  const [fullComm, setFullComm] = useState([]);
  const [game_phase, setGamePhase] = useState(null);
  const [incomingEvent, setIncomingEvent] = useState(false);
  const [lineup, setLineup] = useState([]);
  const [location, setLocation] = useState();
  const [marketPriceUpdate, setMarketPriceUpdate] = useState([]);
  const [matchName, setMatchName] = useState(null);
  const [overs, setOvers] = useState("");
  const [partnership, setPartnership] = useState(["", ""]);
  const [probability, setProbability] = useState([
    { name: "", probability: 0 },
    { name: "", probability: 0 },
  ]);
  const [selected, setSelected] = useState("feed");
  const [score, setScore] = useState(["0/0", "0/0"]);
  const [scorecardData, setScorecardData] = useState([]);
  const [scoregrid, setScoregrid] = useState([]);
  const [scoreGridData, setScoreGridData] = useState([]);
  const [striker, setStriker] = useState();
  const [teams, setTeams] = useState([]);
  const [tournament, setTournament] = useState("IPL 2024");
  const [trading, setTrading] = useState([]);
  const [localTime, setLocalTime] = useState(null);
  const [mOvers, setMOvers] = useState([0, 0]);
  const [matchComment, setMatchComment] = useState("");
  const [currentInnings, setCurrentInnings] = useState(0);
  const [watchalong, setWatchalong] = useState(false);
  const [watchalongUrl, setWatchalongUrl] = useState("");
  const [subHeader, setSubHeader] = useState("standard");
  const [stream, setStream] = useState(true);
  const [battingOvers, setBattingOvers] = useState([0, 0, 0, 0]);
  const [embedcode, setEmbedCode] = useState("");

  const [isTraderEnabled, setIsTraderEnabled] = useState(false);
  const [isSquaresEnabled, setIsSquaresEnabled] = useState(false);
  const [squaresForThisMatch, setSquaresForThisMatch] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [runRate, setRunRate] = useState();

  const dte = new Date();
  let eventCount = 0;

  const wsUrl = process.env.FEED_URL;
  const apiKey = process.env.DECIMAL_API_KEY;
  const apiEndpoint = process.env.DECIMAL_ENDPOINT;
  let tournamentFilter = process.env.TOURNAMENT.toLowerCase();
  const navigate = useNavigate();

  const [quickfireEnabled, setQuickfireEnabled] = useState({
    enabled: false,
    competitionToLoad: "",
  });

  const isQuickFireEnabled = async () => {
    const clientTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = new Date();
    const clientDate = date.toISOString().split("T")[0];
    const response = await fetch(
      `${process.env.API_URL}/quickfire/enabled?clientTZ=${clientTimezoneName}&clientDate=${clientDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY,
        },
      }
    );
    if (response.status === 200) {
      setQuickfireEnabled(await response.json());
    }
  };

  const playSquaresForThisMatch = async () => {
    const response = await fetch(
      `${process.env.API_URL}/squaresactivematches/${match_id}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    if (response.status !== 200) {
      setSquaresForThisMatch(false);
    }
    const data = await response.json();
    setSquaresForThisMatch(data);
  };

  const areGamesEnabled = async () => {
    const response = await fetch(`${process.env.API_URL}/isgameenabled/ALL`, {
      method: "GET",
      headers: headers,
    });
    if (response.status !== 200) {
      return;
    }
    const data = await response.json();
    const traderIndex = data.findIndex((x) => x.game_name === "Trader");
    if (traderIndex > -1) {
      setIsTraderEnabled(data[traderIndex].is_enabled);
    }
    const squaresIndex = data.findIndex((x) => x.game_name === "Squares");
    if (squaresIndex > -1) {
      setIsSquaresEnabled(data[squaresIndex].is_enabled);
    }
  };

  useEffect(() => {
    isQuickFireEnabled();
    areGamesEnabled();
    playSquaresForThisMatch();
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 960px)");
    setIsMobile(mediaQuery.matches);
    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    if (scoregrid && scoregrid.length > 0 && selectedParam) {
      setSelected(selectedParam);
    }
  }, [scoregrid]);

  useEffect(() => {
    const fetchEventCount = async (match_id) => {
      try {
        const response = await fetch(
          `${process.env.API_URL}/events_count/${match_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": process.env.API_KEY,
            },
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          if (eventCount !== data.data[0].row_count) {
            eventCount = data.data[0].row_count;
            fetchEvents(match_id);
          }
        }
      } catch (error) {
        console.error("Error fetching event count:", error);
        return null;
      }
    };
    const intervalId = setInterval(() => fetchEventCount(match_id), 60000);
    return () => clearInterval(intervalId);
  }, [match_id]);

  const getEmbedCode = async (match_id) => {
    try {
      const response = await fetch(
        `${process.env.API_URL}/embeddedplayer/${match_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setEmbedCode(data.data);
      }
    } catch (error) {
      return null;
    }
  };

  const fetchEvents = async (match_id) => {
    try {
      const response = await fetch(
        `${process.env.API_URL}/events/${match_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setStream(data.stream.trim());
        if (data.stream.trim() === "2") {
          setSubHeader("stream");
          if (embedcode === "") {
            getEmbedCode(match_id);
          }
        }
        setFeed(data.data);
        setIncomingEvent(false);
      }
    } catch (error) {
      return null;
    }
  };

  const fetchScoreGrid = async (match_id, scoreGridData) => {
    const data = scoreGridData;
    const overs = data.overs;

    let scoreDisplay = [];
    for (let inns = 1; inns <= 4; inns++) {
      const currentInns = overs[`inns${inns}`];
      if (currentInns) {
        currentInns.forEach((over) => {
          let ignoreOver = true;
          for (let i = 1; i <= 12; i++) {
            const ballKey = `b${i}`;
            const ballValue = over[ballKey];
            if (ballValue !== null && ballValue !== "") {
              ignoreOver = false;
              scoreDisplay.push(ballValue);
            }
          }
          if (!ignoreOver) {
            scoreDisplay.push("OVER " + over.ov);
          }
        });
      }
    }
    setScoregrid(scoreDisplay.reverse());
  };

  async function getCompletedData(match_id) {
    try {
      const response = await fetch(
        `${process.env.API_URL}/completed/${match_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.data.event) {
          EventProc(data.data.event[0].msg_data);
        }
        if (data.data.scorecard) {
          setScorecardData(data.data.scorecard[0].msg_data);
          setEventStatus(data.data.scorecard[0].msg_data.event_status);
        }
        if (data.data.scoregrid) {
          setScoreGridData(data.data.scoregrid[0].msg_data);
          fetchScoreGrid(match_id, data.data.scoregrid[0].msg_data);
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      //console.error('Error fetching data:', error);
      return null;
    }
  }

  useEffect(() => {
    if (complete === "true") {
      getCompletedData(match_id);
      setSelected("scorecard");
    } else {
      let socket;
      if (process.env.ENVIRONMENT !== "dev") {
        socket = new WebSocket(`${wsUrl}`);
      } else {
        socket = new WebSocket(
          `${wsUrl}/${match_id}/${apiKey}/APIFEED-${match_id}`
        );
      }
      socket.addEventListener("open", function open() {
        const message = JSON.stringify({
          feed_filter: [
            "event",
            "commentary",
            "match_update",
            "market_update",
            "market_price_update",
            "scorecard",
            "scoregrid",
            "lineups",
          ],
          feed_type: "update_only",
          msg_type: "feed_subscription",
          feed_id: match_id,
        });
        socket.send(message);
      });

      socket.addEventListener("message", function incoming(data) {
        let parsedData = "";
        try {
          parsedData = JSON.parse(data.data);
        } catch (e) {}
        if (parsedData.msg_type) {
          let timeStamp = parseFloat(parsedData.last_update.split("-")[0]);
          const last_update = new Date(timeStamp);
        }
        if (parsedData.lineups) {
          setLineup(parsedData.lineups);
        }
        if (parsedData.match_update) {
          if (
            parsedData.match_update.competition.toLowerCase() !==
              tournamentFilter &&
            tournamentFilter !== ""
          ) {
            navigate("/", { replace: true });
          }
          setFormat(parsedData.match_update.format);
        }
        if (parsedData.scoregrid) {
          setScoreGridData(parsedData.scoregrid);
          fetchScoreGrid(match_id, parsedData.scoregrid);
        }
        if (parsedData.market_update) {
          let tm = trading;
          let markets = parsedData.market_update.markets;
          markets.forEach((mk, id) => {
            if (mk.in_play === "Yes" && mk.priority !== "0") {
              let existingIndex = tm.findIndex(
                (item) => item.market_id === mk.market_id
              );
              if (existingIndex === -1) {
                tm.push(mk);
              } else {
                tm[existingIndex] = mk;
              }
            }
          });
          tm.sort(
            (a, b) =>
              parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
          );
          setTrading(tm);
        }

        //**commentary */
        if (parsedData.commentary) {
          let commentary = parsedData.commentary;
          let comms = fullComm;
          for (let inns = 1; inns <= 4; inns++) {
            let insKey = "inns" + inns;
            if (commentary.commentaries[insKey]) {
              let thisIns = [];
              commentary.commentaries[insKey].forEach((com, idx) => {
                let ballParts = com.ball.toString().split(".");
                let ov = parseInt(ballParts[0], 10);
                let ba = parseInt(ballParts[1]);
                let csplit = com.commentary.split(",");
                let res = csplit[1];
                let fr = csplit[0].split(" to ")[0];
                let tr = csplit[0].split(" to ")[1];
                let newObject = {
                  inns: insKey,
                  over: ov,
                  ball: ba,
                  commentary: com.commentary,
                  ballFrom: fr,
                  ballTo: tr,
                  result: res.trim(),
                };
                let existingIndex = comms.findIndex(
                  (item) =>
                    item.inns === insKey && item.over === ov && item.ball === ba
                );
                if (existingIndex !== -1) {
                  comms[existingIndex].commentary = com.commentary;
                } else {
                  comms.unshift(newObject);
                }
              });
            }
          }
          setFullComm(comms);
        }

        if (parsedData.scorecard) {
          const scorecard = parsedData.scorecard;
          setScorecardData(scorecard);
        }
        if (parsedData.market_price_update) {
          const winPredict = parsedData.market_price_update.markets.find(
            (market) => market.market_id === "1010"
          );
          if (winPredict) {
            let prob1 = Math.round(winPredict.selections[0].probability * 100);
            let prob2 = 100 - prob1;
            setProbability([
              {
                name: winPredict.selections[0].name,
                probability: prob1,
              },
              { name: winPredict.selections[1].name, probability: prob2 },
            ]);
          }
          let cMPU = marketPriceUpdate;
          parsedData.market_price_update.markets.forEach((m, i) => {
            let existingIndex = cMPU.findIndex(
              (item) => item.market_id === m.market_id
            );
            if (existingIndex < 0) {
              cMPU.push(m);
            } else {
              cMPU[existingIndex] = m;
            }
          });
          setMarketPriceUpdate(cMPU);
        }
        if (parsedData.event) {
          EventProc(parsedData.event);
        } else {
          //console.log(`Received: ${match_id} - ${data.data}`);
        }
      });

      socket.addEventListener("error", function error(error) {
        console.error("WebSocket error:", error);
      });

      socket.addEventListener("close", function close(event) {
        //console.log('Disconnected from the WebSocket server:', event);
        //console.log(`Close code: ${event.code}, reason: ${event.reason}`);
      });
      return () => socket.close();
    }
  }, [match_id]);

  async function EventProc(event) {
    const scoreboard = event.scoreboard;
    const matchTitleObj = scoreboard.find((item) => item.name === "matchtitle");
    const game_phaseObj = scoreboard.find((item) => item.name === "game_phase");
    const local_timeObj = scoreboard.find((item) => item.name === "local_time");
    const channelObj = scoreboard.find((item) => item.name === "channel");
    const homeObj = scoreboard.find((item) => item.name === "home");
    const awayObj = scoreboard.find((item) => item.name === "away");
    const battingObj = scoreboard.find(
      (item) => item.name === "Current Batting Team"
    );
    const commentObj = scoreboard.find((item) => item.name === "match_comment");
    setMatchComment(commentObj ? commentObj.value : "");
    if (local_timeObj) {
      let localdt = new Date(local_timeObj.value);
      let formattedDate =
        localdt.getDate().toString().padStart(2, "0") +
        "/" +
        (localdt.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        localdt.getFullYear();
      setLocalTime(formattedDate);
    }

    let matchname = matchTitleObj ? matchTitleObj.value : " , , ";
    setMatchName(matchname.split(",")[1].trim());
    setLocation(matchname.split(",")[2].trim());
    //setTournament(matchname.split(",")[0].trim())
    setGamePhase(game_phaseObj ? game_phaseObj.value : "");
    setTeams([homeObj ? homeObj.value : "", awayObj ? awayObj.value : ""]);
    setBatting(battingObj ? battingObj.value : "");

    const NewPos = [0, 0, 0];
    const CurInns = scoreboard.find((item) => item.name === "Current Innings");
    NewPos[0] = CurInns ? CurInns.value : NewPos[0];
    const CurOvrs = scoreboard.find(
      (item) => item.name === "Current Innings Overs"
    );
    NewPos[1] = CurOvrs ? CurOvrs.value : NewPos[1];
    const CurPart = scoreboard.find(
      (item) => item.name === "Current Innings Part Overs"
    );
    NewPos[2] = CurOvrs ? CurPart.value : NewPos[2];
    if (CurPos !== NewPos) {
      //fetchScoreGrid(match_id);
      setCurPos(NewPos);
    }
    setCurrentInnings(CurInns ? CurInns.value : 0);
    const maxOvers = scoreboard.find((item) => item.name === "Max Overs");
    const batting1 = scoreboard.find((item) => item.name === "batting.1");
    const batting1Runs = scoreboard.find((item) => item.name === "runs.1");
    const batting1Wkts = scoreboard.find((item) => item.name === "wkts.1");
    const batting1MaxOvers = scoreboard.find((item) => item.name === "max.1");
    const batting1Overs = scoreboard.find((item) => item.name === "overs.1");
    const batting2 = scoreboard.find((item) => item.name === "batting.2");

    const batting2Wkts = scoreboard.find((item) => item.name === "wkts.2");
    const batting2MaxOvers = scoreboard.find((item) => item.name === "max.2");
    const batting2Overs = scoreboard.find((item) => item.name === "overs.2");
    const batting3Overs = scoreboard.find((item) => item.name === "overs.3");
    const batting4Overs = scoreboard.find((item) => item.name === "overs.4");
    const batting2Runs = scoreboard.find((item) => item.name === "runs.2");
    let battingScore = "0/0";

    if (CurInns && CurOvrs) {
      const curInnsRunsItem = scoreboard.filter((item) =>
        item.name.includes(`runs.${CurInns.value}`)
      );
      if (curInnsRunsItem && curInnsRunsItem.length > 0) {
        const curInnsRuns = curInnsRunsItem[0].value;
        const curInnsOvers = CurOvrs.value;
        setRunRate((curInnsRuns / curInnsOvers).toFixed(2));
      }
    }

    let b1mo = batting1MaxOvers ? batting1MaxOvers.value : 0;
    let b2mo = batting2MaxOvers ? batting2MaxOvers.value : 0;
    let b1o = batting1Overs ? batting1Overs.value : 0;
    let b2o = batting2Overs ? batting2Overs.value : 0;
    let b3o = batting2Overs ? batting3Overs.value : 0;
    let b4o = batting2Overs ? batting4Overs.value : 0;
    setBattingOvers([b1o, b2o, b3o, b4o]);

    let b1r = batting1Runs ? batting1Runs.value : 0;
    let b2r = batting2Runs ? batting2Runs.value : 0;
    let b1w = batting1Wkts ? batting1Wkts.value : 0;
    let b2w = batting2Wkts ? batting2Wkts.value : 0;

    let teambowl = "",
      teambat = "";

    setMOvers([b1mo, b2mo]);
    let homeOver = b1o + "/" + b1mo;
    if (b1o === b1mo) {
      homeOver = b1mo;
    }
    let awayOver = b2o + "/" + b2mo;
    if (b2o === b2mo) {
      awayOver = b2mo;
    }
    if (awayOver === "") {
      awayOver = "0/" + maxOvers.value;
    }
    let bat1Prog = b1r + "/" + b1w;
    if (bat1Prog === "/") {
      bat1Prog = "0/0";
    }
    let bat2Prog = b2r + "/" + b2w;
    if (bat2Prog === "/") {
      bat2Prog = "0/0";
    }
    let batx1 = batting1 ? batting1.value : "";
    if (batx1 === homeObj.value) {
      setScore([bat1Prog, bat2Prog]);
      setOvers([homeOver, awayOver]);
      battingScore = bat1Prog;
      teambat = awayObj.value;
      teambowl = homeObj.value;
    } else {
      setScore([bat2Prog, bat1Prog]);
      setOvers([awayOver, homeOver]);
      battingScore = bat2Prog;
      teambat = awayObj.value;
      teambowl = homeObj.value;
    }

    let batv = battingObj ? battingObj.value : "";
    const bat1 = scoreboard.find((item) => item.name === batv + "Bat.1");
    const bat2 = scoreboard.find((item) => item.name === batv + "Bat.2");
    const strike1 = scoreboard.find((item) => item.name === "Batsman1 Strike");
    const strike2 = scoreboard.find((item) => item.name === "Batsman2 Strike");
    let bowl1 = scoreboard.find((item) => item.name === "bowler1name");
    let bowl2 = scoreboard.find((item) => item.name === "bowler2name");
    const runs1 = scoreboard.find((item) => item.name === "Batsman1 Runs");
    const runs2 = scoreboard.find((item) => item.name === "Batsman2 Runs");
    let bowl1overs = scoreboard.find((item) => item.name === "bowler1overs");
    let bowl1runs = scoreboard.find((item) => item.name === "bowler1runs");
    let bowl1wicks = scoreboard.find((item) => item.name === "bowler1wickets");
    let bowl2overs = scoreboard.find((item) => item.name === "bowler2overs");
    let bowl2runs = scoreboard.find((item) => item.name === "bowler2runs");
    let bowl2wicks = scoreboard.find((item) => item.name === "bowler2wickets");
    const balls1 = scoreboard.find((item) => item.name === batv + "Bat.1balls");
    const balls2 = scoreboard.find((item) => item.name === batv + "Bat.2balls");
    let strikerbatter = "";
    let batterruns = 0;
    let batterballs = 0;
    if (strike1 ? strike1.value : "1" === "1") {
      strikerbatter = bat1 ? bat1.value : "";
      batterruns = runs1 ? runs1.value : 0;
      batterballs = balls1 ? balls1.value : 0;
    } else {
      strikerbatter = bat2.value;
      batterruns = runs2.value;
      batterballs = balls2.value;
    }
    setStriker(strikerbatter);
    let actbowl = scoreboard.find((item) => item.name === "bowler");
    let bowlovers = "0 overs";
    let bowlruns = "0";
    let bowlwickets = "0";
    if (bowl1 ? bowl1.value : "" !== "") {
      actbowl = bowl1.value;
      bowlovers = bowl1overs.value;
      bowlruns = bowl1runs.value;
      bowlwickets = bowl1wicks.value;
    } else {
      actbowl = bowl2 ? bowl2.value : "";
      bowlovers = bowl2overs ? bowl2overs.value : "";
      bowlruns = bowl2runs ? bowl2runs.value : "";
      bowlwickets = bowl2wicks ? bowl2wicks.value : "";
    }
    setBowling(actbowl);
    setPartnership({
      bowler: actbowl,
      bowlerover: bowlovers,
      bowlerruns: bowlruns,
      bowlerwickets: bowlwickets,
      bowler2: bowl2 ? bowl2.value : "",
      bowlerover2: bowl2overs ? bowl2overs.value : "",
      bowlerruns2: bowl2runs ? bowl2runs.value : "",
      bowlerwickets2: bowl2wicks ? bowl2wicks.value : "",
      bowlteam: teambowl,
      batteam: teambat,
      batter: strikerbatter,
      batterruns: batterruns,
      batterballs: batterballs,
      batter2: bat2?.value ? bat2.value : "",
      batterruns2: runs2?.value ? runs2.value : "",
      batterballs2: balls2?.value ? balls2.value : "",
    });

    /** feed events */
    let scoreIdx = event.score.findIndex((itm) => itm.priority === 1);
    let tBall = parseFloat(event.score[scoreIdx].ball);
    let tEvent = event.score[scoreIdx].result;
    if (
      tEvent.substring(0, 6).toUpperCase() === "WICKET" ||
      tEvent.includes("6 runs") ||
      tEvent.includes("4 runs")
    ) {
      setIncomingEvent(true);
      setTimeout(() => {
        fetchEvents(match_id);
      }, 2000);
    }
  }

  const fetch_settings = async () => {
    const response = await fetch(
      `${process.env.API_URL}/settings/watchalong?match_id=` + match_id,
      {
        method: "GET",
        headers: headers,
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      if (data.upcoming.match_id === match_id) {
        setWatchalong(true);
        setWatchalongUrl(data.upcoming.url);
        setSubHeader("watchalong");
      }
    }
  };
  const headers = {
    "Content-Type": "application/json",
  };
  headers["x-api-key"] = process.env.API_KEY;
  useEffect(() => {
    fetch_settings();
    const updatePageTitle = () => {
      document.title = matchName + " | Cricket8";
    };
    updatePageTitle();
    fetchEvents(match_id);
    return () => {};
  }, [matchName]);

  useEffect(() => {
    if (selected && selected !== "feed" && complete !== "true") {
      const element = document.getElementById("submatch");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selected]);

  const handleSelection = (newSelection) => {
    startTransition(() => {
      setSelected(newSelection);
    });
  };

  return (
    <div id="content" className="content-t">
      <div className="mt-[20px] w-full flex justify-between h-[20px] px-[10px] lg:px-[40px]">
        <Link to="/match" className="flex gap-[4px]">
          <div className="prompt text-[#1b062f] bg-lime flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.25em"
              height="1em"
              viewBox="0 0 16 9"
              className="rotate-180"
            >
              <path
                fill="currentColor"
                d="M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5"
              />
              <path
                fill="currentColor"
                d="M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71s.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z"
              />
            </svg>
          </div>
          <div className="bg-lime flex items-center">
            <span className="font-anton text-[#1b062f] text-[14px] p-[4px]">
              ALL MATCHES
            </span>
          </div>
        </Link>
        <Link to="/games" className="mt-[-18px] mr-[-8px] z-10 lg:hidden">
          <img
            className="h-[100px]"
            src="/img/public/play-trivia-sticker.png"
            alt=""
          />
        </Link>
      </div>

      {subHeader === "standard" ? (
        <div className="MatchPage lg:mt-[10px] px-[10px] lg:px-[40px]">
          <div className="w-full gap-[40px] lg:flex justify-between">
            <div className="">
              <div className="w-[65%] lg:w-full font-anton text-white text-[26px] lg:text-[80px] mt-[10px] uppercase">
                {teams[0]} <span className="text-[#d2ff00]">V</span> {teams[1]}
              </div>
              <div className="flex items-center gap-[10px] justify-between lg:justify-normal mt-[10px] text-[14px] lg:text-[18px]">
                <div className="flex items-center gap-[10px] w-[100%] lg:w-auto">
                  <div className="bg-lime font-anton text-[#1b062f] p-[6px] max-w-[80%] truncate">
                    {matchName?.includes("Match")
                      ? matchName.slice(0, matchName.indexOf("Match"))
                      : matchName}
                  </div>
                  <div className="min-w-[20%] font-chakra normal-case truncate">
                    {matchName?.includes("Match")
                      ? matchName.slice(
                          matchName.indexOf("Match"),
                          matchName.length
                        )
                      : ""}
                  </div>
                </div>
                <div className="min-w-[20%] flex justify-end lg:block">
                  <button
                    className="w-[30px] h-[30px] flex justify-center items-center"
                    style={{ border: "1px solid #D2FF00" }}
                    onClick={openShareModal}
                  >
                    <img src="../img/public/Union.png" />
                  </button>
                </div>
              </div>
              <div className="mt-[10px] mb-[30px] font-chakra text-white text-[12px] lg:text-[18px] flex">
                <div className="min-w-[10%] lg:min-w-[1px]">{localTime}</div>
                <div>
                  <span className="text-[#7cf732] ml-2 mr-2 relative">•</span>
                </div>
                <div className="truncate normal-case">{location}</div>
                <div>
                  <span className="text-[#7cf732] ml-2 mr-2 relative">•</span>
                </div>
                <div className="min-w-[25%] lg:min-w-[1px] truncate">
                  {game_phase === "In Play" ? (
                    <span className="!text-[#d73261] flex normal-case items-center">
                      <div>&nbsp;&nbsp;In-play</div>
                      <div className="live-indicator mt-[2px] mr-[10px] scale-[145%]">
                        <div className="inner"></div>
                      </div>
                    </span>
                  ) : (
                    <span className="text-[#f73261] normal-case items-center">
                      &nbsp;&nbsp;{game_phase}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[100%] lg:max-w-[35%]">
              <ScoreBox
                teams={teams}
                batting={batting}
                score={score}
                overs={overs}
                probability={probability}
                scoregrid={scoregrid}
                partnership={partnership}
                complete={complete}
                eventStatus={eventStatus}
                matchComment={matchComment}
                currentInnings={currentInnings}
                runRate={runRate}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="MatchPage mb-[20px] px-[10px] lg:px-[40px]">
          <div className="w-full lg:gap-[0_40px] gap-[20px] main-container">
            <div className="names-container">
              <div className="mt-[10px] flex">
                <div className="font-anton text-white text-[26px] lg:text-[40px] w-[65%] lg:w-full uppercase">
                  {teams[0]} <span className="text-[#d2ff00]">V</span>{" "}
                  {teams[1]}
                </div>
              </div>
              <div className="flex items-center gap-[10px] justify-between lg:justify-normal mt-[10px] text-[14px] lg:text-[18px]">
                <div className="flex items-center gap-[10px] w-full">
                  <div className="bg-lime font-anton text-[#1b062f] p-[6px] max-w-[60%] truncate">
                    {matchName?.includes("Match")
                      ? matchName.slice(0, matchName.indexOf("Match"))
                      : matchName}
                  </div>
                  <div className="max-w-[25%] font-chakra normal-case truncate">
                    {matchName?.includes("Match")
                      ? matchName.slice(
                          matchName.indexOf("Match"),
                          matchName.length
                        )
                      : ""}
                  </div>
                  <button
                    className="w-[30px] h-[30px] flex justify-center items-center"
                    style={{ border: "1px solid #D2FF00" }}
                    onClick={openShareModal}
                  >
                    <img src="../img/public/Union.png" />
                  </button>
                </div>
              </div>
              <div className="mt-[10px] flex font-chakra text-[12px] lg:text-[18px]">
                <div className="min-w-[10%] lg:min-w-[1px]">{localTime}</div>
                <span className="text-[#7cf732] ml-2 mr-2 relative top-[-3px]">
                  •
                </span>
                <div className="truncate normal-case">{location}</div>
                <span className="text-[#7cf732] ml-2 mr-2] relative top-[-3px]">
                  •
                </span>
                <div className="min-w-[25%] lg:min-w-[1px] truncate">
                  {game_phase === "In Play" ? (
                    <span className="!text-[#d73261] flex normal-case items-center">
                      <div>&nbsp;&nbsp;In-play</div>
                      <div className="live-indicator mr-[10px] scale-[145%]">
                        <div className="inner"></div>
                      </div>
                    </span>
                  ) : (
                    <span className="text-[#f73261] normal-case items-center">
                      &nbsp;&nbsp;{game_phase}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                aspectRatio: "16 / 9",
              }}
              className="video-container mt-[10px] flex"
            >
              {watchalong === true && (
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  width="100%"
                  height="100%"
                  src={watchalongUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              )}
            </div>
            <div className="mt-[10px] scorebox-container">
              <ScoreBox
                teams={teams}
                batting={batting}
                score={score}
                overs={overs}
                probability={probability}
                scoregrid={scoregrid}
                partnership={partnership}
                complete={complete}
                eventStatus={eventStatus}
                matchComment={matchComment}
                currentInnings={currentInnings}
                runRate={runRate}
              />
            </div>
          </div>
        </div>
      )}
      <div id="submatch" className="flex flex-col">
        <SubMenu
          selected={selected}
          setSelected={handleSelection}
          complete={complete}
        />
        <div className="relative subs pb-[20px] bg-[#532A86] min-h-[200px] mx-0 w-[100vw] lg:w-[100%] px-[20px] lg:px-[40px]">
          <div className="relative w-[100%] h-[100px] mt-[-20px] mb-[-60px] pt-0 left-0 [background:linear-gradient(180deg,rgb(27,6,47)_0%,rgba(27,6,47,0)_100%)] opacity-10" />
          {selected === "commentary" ? (
            <div>
              <Commentary
                commentary={commentary}
                fullComm={fullComm}
                teams={teams}
                probability={probability}
                scoregrid={scoreGridData}
                maxovers={mOvers}
              />
            </div>
          ) : selected === "scorecard" ? (
            <div>
              <Scorecard
                scorecard={scorecardData}
                maxovers={mOvers}
                striker={striker}
                bowling={bowling}
                teams={teams}
                probability={probability}
                scoregrid={scoreGridData}
                overs={overs}
                complete={complete}
                battingovers={battingOvers}
              />
            </div>
          ) : selected === "charts" ? (
            <div>
              <Charts
                teams={teams}
                probability={probability}
                scoregrid={scoreGridData}
                maxovers={mOvers}
                complete={complete}
              />
            </div>
          ) : selected === "trading" ? (
            <div>
              <Trading
                trading={trading}
                marketprice={marketPriceUpdate}
                teams={teams}
                probability={probability}
                scoregrid={scoreGridData}
                maxovers={mOvers}
              />
            </div>
          ) : selected === "standings" ? (
            <div>
              <Standings
                teams={teams}
                probability={probability}
                scoregrid={scoreGridData}
                maxovers={mOvers}
                complete={complete}
              />
            </div>
          ) : selected === "feed" ? (
            <div className="flex flex-col md:flex-row md:gap-[20px]">
              <div className="flex flex-col w-full md:w-[50%] lg:w-[100%]">
                {quickfireEnabled.enabled && (
                  <TriviaQuizzes isMobile={isMobile} />
                )}

                <div className="anOver w=[100%] mt-[15px] mb-[10px] bgwhite flex-grow">
                  <Feed
                    feed={feed}
                    incomingEvent={incomingEvent}
                    fullComm={fullComm}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </div>

                            <div className='lg:px-[20px] flex flex-col md:w-[400px]'>
                                {/*TODO: removed only for this release, uncomment it later*/}
                                {/*{isSquaresEnabled && squaresForThisMatch && (*/}
                                {/*    <SquaresBanner isMobile={true}/>*/}
                                {/*)}*/}

                                {/*{isTraderEnabled && <TraderBanner isMobile={true}/>}*/}
                                <div className='hidden md:inline'>
                                    <Charts
                                        teams={teams}
                                        probability={probability}
                                        scoregrid={scoreGridData}
                                        maxovers={mOvers}
                                        title={false}
                                        tp={'0px'}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : selected === 'lineup' ? (
                        <div>
                            <div className='flex md:gap-[20px]'>
                                <div className='anOver w=[100%] mt-[0px] mb-[10px] bgwhite flex-grow'>
                                    <Lineup
                                        lineup={lineup}
                                        selected={selected}
                                        setSelected={setSelected}
                                    />
                                </div>
                                <div className='hidden lg:inline mt-[20px]'>
                                    <Charts
                                        teams={teams}
                                        probability={probability}
                                        scoregrid={scoreGridData}
                                        maxovers={mOvers}
                                        title={false}
                                        tp={'0px'}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>{selected}</div>
                    )}
                </div>
            </div>
            <ShareModal
                isOpen={isShareModalOpen}
                onRequestClose={closeShareModal}
                url={window.location.href}
            />
        </div>
    );
};
export default MatchDetail;
