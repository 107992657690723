import React from 'react';
import { Link } from 'react-router-dom';

const TriviaQuizzes = ({isMobile}) => {
  return (
    <div className={`w-full flex mt-[20px] ${isMobile ? '!w-[340px]' : ''}`} style={{ backgroundImage: "url('/img/public/trivia-bubbles.png')" }}>
      <div className={`w-[60%] flex flex-col gap-[20px] pb-[20px] ${isMobile ? '': '!px-[40px] !gap-[10px] !w-full'}`}>
        <div className='bg-lime text-[#1b062f] w-[fit-content] p-[10px] flex items-center max-h-[30px]'>
          <span className='text-[14px]'>C8 GAMES</span>
        </div>
        <div className={`px-[15px] flex flex-col gap-[20px] ${isMobile ? '' : '!justify-between !px-[0px] !flex-row'}`}>
          <span className='uppercase text-[26px] leading-[26px]'>
            play quickfire on <span className='text-[#D2FF00]'>Qs</span>
          </span>
          <Link
            to={'/games'}
            className={`z-10 uppercase text-[#1b062f] text-[20px] bg-lime p-[10px] w-[fit-content] flex items-center max-h-[40px] ${isMobile ? '' : 'mt-[-18px]'}`}
          >
            PLAY Qs
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1.25em'
              height='1em'
              viewBox='0 0 16 9'
            >
              <path
                fill='currentColor'
                d='M12.5 5h-9c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9c.28 0 .5.22.5.5s-.22.5-.5.5'
              />
              <path
                fill='currentColor'
                d='M10 8.5a.47.47 0 0 1-.35-.15c-.2-.2-.2-.51 0-.71l3.15-3.15l-3.15-3.15c-.2-.2-.2-.51 0-.71s.51-.2.71 0l3.5 3.5c.2.2.2.51 0 .71l-3.5 3.5c-.1.1-.23.15-.35.15Z'
              />
            </svg>
          </Link>
        </div>
      </div>
      <div className={`w-[40%] ${isMobile ? '' : 'hidden'}`}>
        <div className='relative flex flex-col items-center justify-center h-full'>
            <img className='mt-[25px]' src="/img/public/trivia-mobile.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default TriviaQuizzes;
